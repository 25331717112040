<template>
  <div class="order-management">
    <div style="padding: 8px">
      <h3 style="text-align: center">提交打包说明</h3>
      <p>
        1、由于国际物流的特殊性，进入仓库的包裹视为同意我公司验货，如您提交的包裹是非普货。
      </p>
      <p>
        2、提交打包前请注意您的包裹是否全部到齐，在打包完成后拆包或者重复打包将会产生额外的打包费用。
      </p>
      <p>3、易碎物品、贵重物品等请在提交打包后中的备注说明中写明。</p>
      <p>
        4、工作时间范围内（周一到周五10：00-17：00
        周六10：00-14：00），排队、出库、打包等整体环节一般在5小时内可以完成
      </p>
      <p>
        5、如某个或某些包裹需要国内退货，请与客服联系或者登录电脑版官网自助操作。
      </p>
    </div>
    <div style="text-align: center; margin-top: 30px">
      <button class="nq-button">关闭</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'login',
  data() {
    return {
      showDetail: false
    }
  },

  mounted() {},
  methods: {}
}
</script>
<style lang="less" scoped>
@import url("./order.less");
</style>
